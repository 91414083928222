<template>
  <div class="tabFeed">
    <div class="posts">
      <PostForm
        v-if="formDisplay"
        :creator="creator"
        :moderator="moderator"
        :groupId="groupId"
        :group="true"
        :tabId="tabView"
        :isMainTab="isMainTab"
        :isAdminOnlyTab="adminOnly"
        @refresh="updateTab"
      />
      <div v-if="model.exposed.length > 0" class="exposed">
        <h4>
          Pinned posts
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-pin-angle"
            viewBox="0 0 16 16"
          >
            <path
              d="M9.828.722a.5.5 0 0 1 .354.146l4.95 4.95a.5.5 0 0 1 0 .707c-.48.48-1.072.588-1.503.588-.177 0-.335-.018-.46-.039l-3.134 3.134a5.927 5.927 0 0 1 .16 1.013c.046.702-.032 1.687-.72 2.375a.5.5 0 0 1-.707 0l-2.829-2.828-3.182 3.182c-.195.195-1.219.902-1.414.707-.195-.195.512-1.22.707-1.414l3.182-3.182-2.828-2.829a.5.5 0 0 1 0-.707c.688-.688 1.673-.767 2.375-.72a5.922 5.922 0 0 1 1.013.16l3.134-3.133a2.772 2.772 0 0 1-.04-.461c0-.43.108-1.022.589-1.503a.5.5 0 0 1 .353-.146zm.122 2.112v-.002.002zm0-.002v.002a.5.5 0 0 1-.122.51L6.293 6.878a.5.5 0 0 1-.511.12H5.78l-.014-.004a4.507 4.507 0 0 0-.288-.076 4.922 4.922 0 0 0-.765-.116c-.422-.028-.836.008-1.175.15l5.51 5.509c.141-.34.177-.753.149-1.175a4.924 4.924 0 0 0-.192-1.054l-.004-.013v-.001a.5.5 0 0 1 .12-.512l3.536-3.535a.5.5 0 0 1 .532-.115l.096.022c.087.017.208.034.344.034.114 0 .23-.011.343-.04L9.927 2.028c-.029.113-.04.23-.04.343a1.779 1.779 0 0 0 .062.46z"
            />
          </svg>
        </h4>
        <!-- Pinned Posts -->
        <PostExposed
          v-for="exposedPost in model.exposed"
          :key="exposedPost.id"
          :id_group="groupId"
          v-bind="exposedPost"
        />
      </div>
      <!-- Tab Feed -->
      <div class="posts" v-if="!isMainTab">
        <div class="loader" v-if="loadingPosts">
          <div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div v-else>
          <div v-if="model.tabPosts.length > 0">
            <Post
              v-for="tabPost in model.tabPosts"
              :key="tabPost.id"
              v-bind="tabPost"
              :creator="creator"
            />
            <div class="loader" v-if="loadingMore">
              <div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
          <h2 v-if="model.tabPosts.length < 1">No posts yet</h2>
        </div>
      </div>
      <!-- Main feed -->
      <div class="posts" v-else>
        <div v-if="model.mainTabPosts.length > 0">
          <Post
            v-for="mainTabPost in model.mainTabPosts"
            :key="mainTabPost.id"
            v-bind="mainTabPost"
            :creator="creator"
            :moderator="moderator"
          />
        </div>
        <h2 v-if="model.mainTabPosts.length < 1">No posts yet</h2>
      </div>
    </div>
  </div>
</template>

<script>
//Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
// Components
import PostForm from "@/components/post/PostForm"
import PostExposed from "@/components/post/PostExposed"
import Post from "@/components/post/Post"

export default {
  name: "GroupFeed",
  components: { PostForm, PostExposed, Post },
  props: {
    adminOnly: Boolean,
    creator: Boolean,
    moderator: Boolean,
    isMember: Boolean,
    groupId: Number,
    tabView: Number,
  },
  data() {
    return {
      model: {
        mainTabPosts: [],
        tabPosts: [],
        exposed: [],
        tabs: [],
      },
      loadingPosts: true,
      loadingMore: false,
      postsCount: 0,
      limit: 5,
      trackOffset: 0,
      errorForExposed: "",
      errorForPosts: "",
      timeout: undefined
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
    this.getAllTabPosts()
    this.getAllExposedTabPosts()
    this.getGroupTabs()
    this.getGroupPosts()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    throttle(func, limit) {
      if (!this.timeout) {
        func();
        this.timeout = setTimeout(() => {
          this.timeout = undefined;
        }, limit);
      }
    },
    async onScroll() {
      const windowHeight = window.innerHeight;
      const scrollOffset = window.pageYOffset + windowHeight;
      const pageHeight = document.querySelector('.main').offsetHeight;
      if (scrollOffset + 200 >= pageHeight) {
        if(this.model.tabPosts.length === this.postsCount) {
          return;
        }

        this.throttle(this.loadMore, 500);
      }
    },
    async getAllTabPosts(limit = 5, offset = 0) {
      this.loadingPosts = true;
      this.trackOffset = 0;
      this.postsCount = 0;

      const response = await this.$api.getTabPosts(this.groupId, this.tabView, limit, offset, this.userStore.accessToken)
      const { success, posts, postsCount } = response;
      if(!success) {
        this.loadingPosts = false;
        return;
      }

      if(!this.isMainTab) {
        this.model.tabPosts = posts;
        this.postsCount = postsCount;
      } else {
        this.model.tabPosts = []
        this.errorForPosts = response.msg
      }
      this.loadingPosts = false;
    },
    async loadMore() {
      this.trackOffset += 5;
      this.loadingMore = true;
      const response = await this.$api.getTabPosts(this.groupId, this.tabView, this.limit, this.trackOffset, this.userStore.accessToken)
      const { success, posts } = response;
      if(!success) {
        this.loadingMore = false;
        return;
      }
      this.model.tabPosts.push(...posts);
      this.loadingMore = false;
    },
    async getAllExposedTabPosts() {
      const response = await this.$api.getExposedTabPosts(
        this.groupId,
        this.tabView,
        this.userStore.accessToken
      )
      if (response.success) {
        this.model.exposed = response.exposedTabPosts
      } else {
        this.model.exposed = []
        this.errorForExposed = response.msg
      }
    },
    
    async getGroupPosts() {
      const response = await this.$api.getGroupInfo(this.groupId, null, this.userStore.accessToken)

      if (response.success) {
        this.model.mainTabPosts = [...response.posts]
      }
    },
    async getGroupTabs() {
      const response = await this.$api.getTabs(this.groupId, this.userStore.accessToken)

      if (response.success) {
        this.model.tabs = response.tabs
      }
    },
    updateTab() {
      this.getAllExposedTabPosts()
      this.getAllTabPosts()
      this.getGroupPosts()
    },
  },
  watch: {
    tabView() {
      this.getAllTabPosts()
      this.getAllExposedTabPosts()
      this.getGroupPosts()
    }
  },
  computed: {
    ...mapStores(useUserStore),
    isMainTab() {
      for (let tab of this.model.tabs) {
        if (tab.id === this.tabView) {
          if (tab.name === "Main") {
            return true
          } else {
            return false
          }
        }
      }
    },
    formDisplay() {
      if(this.adminOnly){
        if(this.creator || this.moderator) return true
        else return false
      } else {
        if(this.isMember) return true
        else return false
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.tabFeed {
  max-width: 80%;

  .posts {
    padding: 0 10%;

    @include respond-to("medium") {
      width: 50vw;
      padding: 0;
    }

    @include respond-to("small") {
      grid-area: 4;
      width: 100vw;
      padding: 0;
    }

    h5 {
      color: white;
      padding: 20px;
    }
    h2 {
      color: var(--text);
      text-align: center;
      margin: 50px;
    }

    .loader {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px 0px;

      div {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;

        div {
          box-sizing: border-box;
          display: block;
          position: absolute;
          width: 64px;
          height: 64px;
          margin: 8px;
          border: 8px solid white;
          border-radius: 50%;
          animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          border-color: white transparent transparent transparent;

          &:nth-child(1) {
          animation-delay: -0.45s;
          }

          &:nth-child(2) {
          animation-delay: -0.3s;
          }

          &:nth-child(3) {
          animation-delay: -0.15s;
          }
        }
      }
    }
  }
  .posts::v-deep {
    article {
      margin-left: 20px;
      margin-right: 20px;
    }
    .container {
      form {
        margin-left: 20px;
        margin-right: 20px;
      }
    }
  }

  .exposed {
    h4 {
      color: var(--text);
      padding: 10px 20px; /*20px;*/
      background: var(--background-darker);
      margin: 10px 20px;
      border-radius: 15px;

      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
