<template>
  <article v-if="!isDeleted" v-bind:class="{ collapsed: collapsed }">
    <div class="exposed-controls">
      <button @click="collapsed = !collapsed">
        <span v-if="collapsed">Expand</span>
        <span v-else>Collapse</span>
      </button>
      <p>{{ ellipsis }}</p>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-pin-fill"
        viewBox="0 0 16 16"
      >
        <path
          d="M4.146.146A.5.5 0 0 1 4.5 0h7a.5.5 0 0 1 .5.5c0 .68-.342 1.174-.646 1.479-.126.125-.25.224-.354.298v4.431l.078.048c.203.127.476.314.751.555C12.36 7.775 13 8.527 13 9.5a.5.5 0 0 1-.5.5h-4v4.5c0 .276-.224 1.5-.5 1.5s-.5-1.224-.5-1.5V10h-4a.5.5 0 0 1-.5-.5c0-.973.64-1.725 1.17-2.189A5.921 5.921 0 0 1 5 6.708V2.277a2.77 2.77 0 0 1-.354-.298C4.342 1.674 4 1.179 4 .5a.5.5 0 0 1 .146-.354z"
        />
      </svg>
    </div>
    <div class="info">
      <img id="profileImg" :src="profileImg" @click="redirect()" />

      <h3 v-if="isAdminPost">Admin post</h3>
      <!-- Admin post -->
      <h3 v-else-if="isGroupPost" @click="redirect()">{{ username }}</h3>
      <!-- Group post -->
      <h3 v-else @click="redirect()">{{ username }}</h3>
      <!-- User  post -->

      <h4>{{ profileType }}</h4>
      <!-- Type of profile -->
      <div v-if="id_user === this.$store.getId()" class="post-controls">
        <b-dropdown dropleft id="post-controls">
          <template #button-content>
            <b-icon-chevron-down></b-icon-chevron-down>
          </template>
          <b-dropdown-item-button
            v-if="type === 'table'"
            @click="showTableEditor = true"
          >
            Edit
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-pencil"
              viewBox="0 0 16 16"
            >
              <path
                d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"
              />
            </svg>
          </b-dropdown-item-button>
          <b-dropdown-item-button @click="unpinPost">
            Unpin
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-pin-angle"
              viewBox="0 0 16 16"
            >
              <path
                d="M9.828.722a.5.5 0 0 1 .354.146l4.95 4.95a.5.5 0 0 1 0 .707c-.48.48-1.072.588-1.503.588-.177 0-.335-.018-.46-.039l-3.134 3.134a5.927 5.927 0 0 1 .16 1.013c.046.702-.032 1.687-.72 2.375a.5.5 0 0 1-.707 0l-2.829-2.828-3.182 3.182c-.195.195-1.219.902-1.414.707-.195-.195.512-1.22.707-1.414l3.182-3.182-2.828-2.829a.5.5 0 0 1 0-.707c.688-.688 1.673-.767 2.375-.72a5.922 5.922 0 0 1 1.013.16l3.134-3.133a2.772 2.772 0 0 1-.04-.461c0-.43.108-1.022.589-1.503a.5.5 0 0 1 .353-.146zm.122 2.112v-.002.002zm0-.002v.002a.5.5 0 0 1-.122.51L6.293 6.878a.5.5 0 0 1-.511.12H5.78l-.014-.004a4.507 4.507 0 0 0-.288-.076 4.922 4.922 0 0 0-.765-.116c-.422-.028-.836.008-1.175.15l5.51 5.509c.141-.34.177-.753.149-1.175a4.924 4.924 0 0 0-.192-1.054l-.004-.013v-.001a.5.5 0 0 1 .12-.512l3.536-3.535a.5.5 0 0 1 .532-.115l.096.022c.087.017.208.034.344.034.114 0 .23-.011.343-.04L9.927 2.028c-.029.113-.04.23-.04.343a1.779 1.779 0 0 0 .062.46z"
              />
            </svg>
          </b-dropdown-item-button>
          <b-dropdown-divider v-if="type === 'table'"></b-dropdown-divider>
          <b-dropdown-item-button @click="showDeleteModal = true">
            Remove
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-trash"
              viewBox="0 0 16 16"
            >
              <path
                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
              />
              <path
                fill-rule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
              />
            </svg>
          </b-dropdown-item-button>
        </b-dropdown>
        <div v-bind:class="{ show: showDeleteModal }" class="remove-modal">
          <div class="box">
            <span
              >This will permanently remove the selected post. Do you wish to
              continue?</span
            >
            <div class="controls">
              <button type="button" @click="deletePost">Yes</button>
              <button type="button" @click="showDeleteModal = false">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="!isAdminPost && !isGroupPost"
      class="tags"
      :title="formattedTags"
    >
      <span class="tag" v-for="tag in tagsArray" :key="tag">{{ tag }}</span>
      <span
        v-if="!isAdminPost && !isGroupPost && tagButtonVisible"
        class="tag extendTagsButton"
        @click="extendTags = true"
      >
        ...
      </span>
    </div>

    <div class="postContent">
      <p v-html="formatted"></p>
      <!-- Post text -->

      <img v-if="hasImage" id="postImg" :src="img_url" @click="openPost(id)" />
      <!-- Post image -->

      <LinkPreview
        v-if="!hasImage"
        :title="preview_title"
        :img="preview_img"
        :url="preview_url"
      />
      <!-- Post link preview -->
      <static-table :table-data="tableData"></static-table>
      <c-table-manage-form
        :show="showTableEditor"
        @close="closeTableEditor"
        @tableUpdated="updateTableData"
        :table-data="editableTableData"
        ref="tableForm"
      ></c-table-manage-form>
    </div>

    <div class="postInfo" @click="openPost(id)">
      <p>{{ setComments }}</p>
      <p>{{ $formatter.formatTimeSince(date_posted) }}</p>
    </div>

    <CommentForm :postId="id" @refresh="refreshComments" @click.native="openPost(id, true)"/>
    <!-- <Comments :key="commentKey" :postId="id" /> -->
  </article>
  <article class="deleted" v-else>
    This post has been successfully removed.
  </article>
</template>

<script>
//Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
// Components
import Comments from "@/components/post/Comments";
import CommentForm from "@/components/post/CommentForm";
import LinkPreview from "@/components/post/LinkPreview";
import StaticTable from "@/components/StaticTable";
import CTableManageForm from "@/components/group/CTableManageForm";
import {
  BDropdown,
  BDropdownDivider,
  BDropdownItemButton,
  BIconChevronDown,
} from "bootstrap-vue";

export default {
  name: "Post",
  components: {
    StaticTable,
    Comments,
    CommentForm,
    LinkPreview,
    CTableManageForm,
    BDropdown,
    BDropdownDivider,
    BDropdownItemButton,
    BIconChevronDown,
  },
  props: {
    id: Number,
    text: String,
    img_url: String,
    date_posted: Number,
    tags: String,
    username: String,
    user_img: String,
    id_user: Number,
    admin: Number,
    group: Number,
    id_group: Number,
    preview_title: String,
    preview_img: String,
    preview_url: String,
    exposed: Number,
    type: String,
    tableData: {
      type: Object,
      default() {
        return {
          columns: [],
          rows: [],
        };
      },
    },
  },
  data() {
    return {
      comments: [],
      extendTags: false,
      showTableEditor: false,
      isDeleted: false,
      showDeleteModal: false,
      editableTableData: {},
      collapsed: true,
    };
  },
  methods: {
    async redirect() {
      if (this.isGroupPost && !this.isNotOnGroupUrl()) {
        const response = await this.$api.getTabs(this.id_group, this.userStore.accessToken);
        if (response.success) {
          this.$router.push({
            path: "/group/" + this.id_group + "/tab/" + response.tabs[0].id,
          });
        }
      } else if (!this.isAdminPost){
        const getHash = await this.$api.getHash(this.id_user, this.userStore.accessToken);
				if(getHash.success) {
					const hash = getHash.hash.hash_email;
					this.$router.push({ path: `/user/${this.username}-${hash}` });
				} else {
					this.$router.push({ path: `/` });
				}
      }
    },
    /**
     * Update the local table data from table form.
     * @param data
     */
    async updateTableData(data) {
      let initialTableData = JSON.parse(this.text);
      // update table data on server
      const response = await this.$api.updatePost(
        {
          id: this.id,
          text: JSON.stringify({
            msg: initialTableData.msg,
            tbl: data,
          }),
        },
        this.$store.getId()
      );

      if (response.success === true) {
        // update the original table data
        let updatedText = JSON.stringify({
          msg: initialTableData.msg,
          tbl: data,
        });
        this.$emit("post-updated", {
          postId: this.id,
          updatedText: updatedText,
        });
        this.tableData.columns = data.columns;
        this.tableData.rows = data.rows;
      } 
    },
    /**
     * Close the table editor.
     */
    async refreshComments() {
      let response = await this.$api.getComments(this.id, this.userStore.accessToken);

      if (response.success === true) {
        this.comments = response.comments;
      }
    },
    openPost(postId, clickOnInp = false) {
        if(clickOnInp) {
        this.$router.push({ path: "/post/" + postId, query: { focusInput: clickOnInp, inGroup: true } })
      } else {
        this.$router.push({ path: "/post/" + postId })
      }
    },
    closeTableEditor() {
      //this.editableTableData = JSON.parse(this.text).tbl;
      this.editableTableData = this.assignNewObject;
      this.showTableEditor = false;
    },
    /**
     * Update the selected post with delete date.
     * @returns {Promise<void>}
     */
    async deletePost() {
      const response = await this.$api.updatePost(
        {
          id: this.id,
          deleted: true,
        },
        this.$store.getId()
      );

      if (response.success === true) {
        // update the state
        this.isDeleted = true;
      } 
    },
    /**
     * Unpin the exposed post.
     * @returns {Promise<void>}
     */
    async unpinPost() {
      const response = await this.$api.updatePost(
        {
          id: this.id,
          exposed: 0,
        },
        this.$store.getId()
      );

      if (response.success === true) {
        // update the state
        this.$emit("refresh");
      } 
    },
    async getComments() {
      let response = await this.$api.getComments(this.id, this.userStore.accessToken);

      if (response.success === true) {
        this.comments = response.comments;
      } else {
        this.comments = [];
      }
    },
    newObject() {
      return JSON.parse(this.text).tbl;
    },
    isNotOnGroupUrl() {
      const str = this.$route.path
        .split("")
        .splice(0, 7)
        .join("");
      return str === "/group/";
    },
  },
  computed: {
    ...mapStores(useUserStore),
    profileImg() {
      if (this.group && this.user_img === "") return require("@/assets/img/defaultGroup.jpg");
      if (this.type === "pinned") {
        if (this.$store.adminImgExists()) {
          let adminImg = this.$store.getAdminImg();
          if (adminImg) {
            return adminImg;
          } else {
            return require("@/assets/img/default.jpg");
          }
        } else {
          return require("@/assets/img/default.jpg");
        }
      }

      return this.$formatter.formatProfileImg(this.user_img);
    },
    formattedTags() {
      return this.tags.replaceAll(",", ", ");
    },
    tagsArray() {
      if (this.extendTags) return this.tags.split(",");
      else return this.tags.split(",").slice(0, 3);
    },
    tagButtonVisible() {
      return this.tags.split(",").length > 3 && this.extendTags === false;
    },
    setComments() {
      return this.comments.length === 1
        ? this.comments.length + " Comment"
        : this.comments.length + " Comments";
    },
    isAdminPost() {
      return this.admin === 1;
    },
    isGroupPost() {
      return this.group === 1;
    },
    formatted() {
      let message = "";
      if (this.type === "table") {
        let tableData = JSON.parse(this.text);
        message = tableData.msg;
        this.tableData.columns = tableData.tbl.columns;
        this.tableData.rows = tableData.tbl.rows;
      } else {
        message = this.text;
      }

      // Find all URL's in posts text and format it into html
      const urlMatcher = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gim;
      return message.replace(
        urlMatcher,
        (match) =>
          `<a target="_blank" rel="noopener noreferrer" style="color: orange" href="${match}">${match}</a>`
      );
    },
    ellipsis() {
      let date = new Date(this.date_posted * 1000).toLocaleDateString(),
        time = new Date(this.date_posted * 1000).toLocaleTimeString();
      //req.body[field] = `${year}-${month}-${date} ${hour}:${minute}:${second}`;
      let message = "";
      if (this.type === "table") {
        let tableData = JSON.parse(this.text);
        message =
          tableData.msg === ""
            ? `Table ${date} ${time}`
            : tableData.msg.substring(0, 30) + " ...";
        this.tableData.columns = tableData.tbl.columns;
        this.tableData.rows = tableData.tbl.rows;
      } else {
        message = this.text.substring(0, 30) + " ...";
      }
      return message;
    },
    hasImage() {
      return (
        this.img_url !== null &&
        this.img_url !== undefined &&
        this.img_url !== ""
      );
    },
    profileType() {
      if (this.isAdminPost) return "by EFAN admins";
      if (this.isGroupPost) return "Group post";
      return "Personal profile";
    },
    /**
     * Create an unconnected copy of the original object.
     * @returns {*}
     */
    assignNewObject() {
      if (this.type === "table") {
        return JSON.parse(this.text).tbl;
      } else return {};
    },
  },
  mounted() {
    this.editableTableData = this.assignNewObject;
  },
};
</script>

<style lang="scss" scoped>
@keyframes removedFadeout {
  0% {
    opacity: 1;
    height: 5em;
  }
  80% {
    opacity: 1;
    height: 5em;
  }
  100% {
    opacity: 0;
    height: 0;
  }
}
article {
  margin: 0 0 10px;

  text-align: center;
  color: var(--text);
  border: none;
  padding-top: 0;
  max-height: 2000px;
  overflow: hidden;

  transition: all ease-in-out 0.4s;
  background: var(--background-dark);
  border-radius: 15px;

  &.collapsed {
    max-height: 80px;
    overflow: hidden;
  }

  &.deleted {
    height: 5em;
    line-height: 5em;
    padding-top: 0;
    margin-bottom: 0;
    overflow: hidden;
    color: var(--text-darker);
    animation: removedFadeout 5s ease-out both;
  }

  .exposed-controls {
    @include gradient();
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 5px;
    color: var(--text-light);
    p {
      margin: 10px 10px;
    }
    button {
      @include button(5px, false);
      padding: 5px 10px;
    }
    svg {
      margin: 12px;
    }
  }

  .remove-modal {
    z-index: 100;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: var(--background-light);
    padding: 32px;
    border-radius: 15px;
    text-align: center;
    box-shadow: 0 4px 30px var(--background-dark);
    display: none;
    &.show {
      display: grid;
    }
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    grid-template-areas:
      "text"
      "buttons";
    span {
      grid-area: text;
    }
    .controls {
      grid-area: buttons;
      button {
        @include button(10px, false);
        margin-top: 2em;
      }
    }
  }

  @include respond-to("small") {
    margin-left: 0;
    margin-right: 0;

    .remove-modal {
      padding: 16px;
    }
  }

  .info {
    display: grid;
    grid-template-columns: 70px auto 50px;
    grid-template-rows: auto auto;
    grid-template-areas:
      "image name controls"
      "image type bottomControls";

    padding: 8px;
    text-align: left;

    h3 {
      // User info
      @extend .pointer;
      @include dot-overflow;
      grid-area: name;
      margin: 0;
      margin-top: 5px;

      font-size: 1.3em;
    }
    h4 {
      // Type of profile
      margin: 0;
      font-size: 0.8em;
      font-weight: normal;
      grid-area: type;
    }
    #profileImg {
      grid-area: image;
      @extend .pointer;
      @extend .nodrag;
      @extend .noselect;

      @include profile-img(60px);
      @include box-shadow($color: rgba(0, 0, 0, 0.2));
    }

    .post-controls {
      grid-area: controls;
      .b-dropdown::v-deep {
        > button {
          transform: rotateZ(0deg);
          line-height: 0;
          transition: all 0.3s;
          &:hover {
            transform: rotateZ(0deg) scale(1.05, 1.05) !important;
          }
        }
        &.show {
          > button {
            transform: rotateZ(90deg) !important;
          }
        }
        button {
          @include button(10px, false, var(--medium));
        }
        .dropdown-menu {
          position: absolute;
          display: none;
          z-index: 10;
          background: var(--background-light);
          padding: 8px 0;
          box-shadow: 3px 3px 10px var(--background-dark);
          outline: none;
          &.show {
            display: initial;
          }
          li {
            display: block;
            width: 100%;
            padding: 0;
            margin: 0;
            button {
              display: flex;
              justify-content: space-between;
              width: inherit;
              margin: 0;
              background: transparent;
              border-radius: 0;
              white-space: nowrap;
              padding: 4px 10px;
              &:hover {
                background: var(--medium);
              }
              svg {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }

  .tags {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 5px;

    .tag {
      padding: 8px;
      margin-right: 5px;
      margin-top: 4px;
      border-radius: 15px;
      background-color: var(--dark);
      font-size: 0.8em;
    }
    .extendTagsButton {
      @extend .pointer;
      @extend .noselect;
    }
  }

  // Post content
  .postContent {
    font-size: 1.2em;
    word-wrap: break-word;
    padding: 5px;
    height: auto;

    p {
      // Post text
      text-align: left;
      margin-top: 15px;
      margin-bottom: 25px;
    }
    #postImg {
      @include box-shadow($color: rgba(0, 0, 0, 0.3));
      max-width: 100%;
      max-height: 400px;
      border-radius: 15px;

      transition: all 0.4s ease-in-out 0.5s;
      &:hover {
        transform: scale(1.15);
        z-index: 9999;
      }
    }
  }

  // Post info
  .postInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;

    p {
      color: var(--light);
      font-size: 0.9em;
      margin: 0;
      padding: 10px;
      max-width: 400px;
    }
  }
}
</style>
